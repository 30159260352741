const data = [
  {
    name: "Anaïs P.Figueras",
    slug: "anais-figueras",
    position: 1,
    company: "Google",
    url: "www.google.com",
    image: "/speakers/2024/anais-perez.png",
    text: {
      es: `
        <p>Anaïs Pérez Figueras es Directora de Comunicación y Asuntos Públicos de Google España y Portugal.
        </p>
        <p>Licenciada en periodismo por la Universidad Carlos III de Madrid, ha trabajado como periodista en medios de comunicación como Cadena Ser o Informativos Telecinco.
        </p>
        <p>En 2007 entra en Google para liderar, durante 7 años, múltiples áreas de comunicación: consumo, negocio y corporativo, desarrollando proyectos locales con impacto europeo.
        </p>
        <br>
        <p>En 2014 es nombrada directora del departamento para España y Portugal y con el apoyo de su equipo, desarrolla estrategias para los distintos ámbitos y sectores en los que opera la empresa, con el objetivo de acercar a los medios de comunicación y líderes de opinión las acciones que Google realiza en ambos países.
        </p>
        <p>Muy involucrada en acciones de Diversidad, Equidad e Inclusión, participa activamente en foros y desarrolla estrategias dentro de la empresa para crear no solo un pensamiento crítico acerca de la necesidad de tener plantillas más diversas sin también que se produzcan cambios tangibles.
        </p>
        <p>En su tiempo libre Anaïs toca el saxofón y vuela en parapente. Disfrutar de la buena comida, de los amigos y la familia.
        </p>
          `,
      ca: `
      <p>Anaïs Pérez Figueras és Directora de Comunicació i Assumptes Públics de Google Espanya i Portugal.
      </p>
      <p>Llicenciada en periodisme per la Universitat Carles III de Madrid, ha treballat com a periodista en mitjans de comunicació com a Cadena Ser o Informatius Telecinco.
      </p>
      <p>En 2007 entra en Google per a liderar, durant 7 anys, múltiples àrees de comunicació: consum, negoci i corporatiu, desenvolupant projectes locals amb impacte europeu.
      </p>
      <br>
      <p>En 2014 és nomenada directora del departament per a Espanya i Portugal i amb el suport del seu equip, desenvolupa estratègies per als diferents àmbits i sectors en els quals opera l'empresa, amb l'objectiu d'acostar als mitjans de comunicació i líders d'opinió les accions que Google realitza en tots dos països.
      </p>
      <p>Molt involucrada en accions de Diversitat, Equitat i Inclusió, participa activament en fòrums i desenvolupa estratègies dins de l'empresa per a crear no sols un pensament crític sobre la necessitat de tenir plantilles més diverses sense també que es produeixin canvis tangibles.
      </p>
      <p>En el seu temps lliure Anaïs toca el saxofon i vola en parapent. Gaudir del bon menjar, dels amics i la família.
      </p>
        `,
      en: `
      <p>Anaïs Pérez Figueras is Director of Communication and Public Affairs at Google Spain and Portugal.
      </p>
      <p>Graduated in journalism from the Carlos III University of Madrid, she has worked as a journalist in media such as Cadena Ser or Informativos Telecinco.
      </p>
      <p>In 2007 she joined Google to lead, for 7 years, multiple communication areas: consumer, business and corporate, developing local projects with European impact.
      </p>
      <br>
      <p>In 2014 she was appointed director of the department for Spain and Portugal and with the support of her team, she develops strategies for the different areas and sectors in which the company operates, with the aim of bringing the actions closer to the media and opinion leaders that Google carries out in both countries.
      </p>
      <p>Very involved in Diversity, Equity and Inclusion actions, she actively participates in forums and develops strategies within the company to create not only critical thinking about the need to have more diverse workforces but also to produce tangible changes.
      </p>
      <p>In her free time Anaïs plays the saxophone and paraglides. She enjoys good food, friends and family.
      </p>        `,
    },
    role: {
      es:
        "Directora de Comunicación y Asuntos Públicos .Google España y Portugal",
      ca:
        "Directora de Comunicació i Assumptes Públics .Google Espanya i Portugal",
      en:
        "Director of Communication and Public Affairs. Google Spain and Portugal",
    },
  },
  {
    name: "Oscar Ordoñez",
    slug: "oscar-ordoñez",
    position: 2,
    company: "Caixabank",
    url: "www.caixabank.com",
    image: "/speakers/2024/oscar-ordoñez.png",
    text: {
      es: `
        <p>Oscar Ordóñez dirige actualmente la Dirección de MK&Experience de Caixabank. Bajo esta unidad gestiona y lidera la comunicación comercial de banco y las acciones de publicidad en grandes medios, así como toda la estrategia de Branded content en redes y plataformas y la parte de entertainment con gran cantidad de acuerdos estratégicos que ofrecen experiencias físicas y contenidos digitales exclusivos a los clientes de la entidad. Lidera el programa CaixabankXperience que trabaja en territorios emocionales como la música, la cultura, los deportes y la gastronomía.</p>
        <p>Oscar empezó su carrera profesional en agencias de publicidad, primero en Alta definición y después en Barcelona Virtual, la agencia de publicidad en Internet pionera en España. Tras cuatro años trabajando para marcas de primer nivel, se incorpora en el año 2000 como responsable de MK Digital a e-laCaixa, filial del banco que nace para especializarse en la gestión de proyectos digitales. Durante 7 años lidera con equipos la creación de portales, campañas digitales y contenidos para cajeros y móviles. En el año 2007 se incorpora al equipo de MK de Caixabank, gestionando la dirección de MK de segmentos en el que desarrolla los programas de fidelización de la entidad (Infantil, LKXA - jóvenes, multiEstrella - familias - Club Ahora - seniors) y posteriormente asume la dirección de Particulares, agrupando durante varios años la responsabilidad de fidelización y captación en una sola unidad de marketing./p>
        <p>Bajo su dirección colaboró activamente entre otros proyectos en la creación y el lanzamiento de Imaginbank y en la creación de proyectos tan disruptivos como ImaginCafe, las oficinas Store y hubs de pagos.</p>
        <p>En Formentera20 Oscar compartirá su visión sobre el papel de la comunicación en las grandes compañías, la evolución de la publicidad y la importancia del content y las experiencias.</p>
      `,
      ca: `
      <p>Oscar Ordóñez dirigeix actualment la Direcció de MK&Experience de CaixaBank. Sota aquesta unitat gestiona i lidera la comunicació comercial de banc i les accions de publicitat en grans mitjans, així com tota l'estratègia de Branded content en xarxes i plataformes i la part de entertainment amb gran quantitat d'acords estratègics que ofereixen experiències físiques i continguts digitals exclusius als clients de l'entitat. Lidera el programa CaixabankXperience que treballa en territoris emocionals com la música, la cultura, els esports i la gastronomia.</p>
      <p>Oscar va començar la seva carrera professional en agències de publicitat, primer en Alta Definició i després a Barcelona Virtual, l'agència de publicitat en Internet pionera a Espanya. Després de quatre anys treballant per a marques de primer nivell, s'incorpora l'any 2000 com a responsable de MK Digital a e-laCaixa, filial del banc que neix per a especialitzar-se en la gestió de projectes digitals. Durant 7 anys lidera amb equips la creació de portals, campanyes digitals i continguts per a caixers i mòbils. L'any 2007 s'incorpora a l'equip de MK de CaixaBank, gestionant la direcció de MK de segments en el qual desenvolupa els programes de fidelització de l'entitat (Infantil, LKXA - joves, multiestrella - famílies - Club Ara - sèniors) i posteriorment assumeix la direcció de Particulars, agrupant durant diversos anys la responsabilitat de fidelització i captació en una sola unitat de màrqueting./p>
      <p>Sota la seva direcció va col·laborar activament entre altres projectes en la creació i el llançament de Imaginbank i en la creació de projectes tan disruptius com ImaginCafe, les oficines Store i hubs de pagaments.</p>
      <p>En Formentera20  compartirà la seva visió sobre el paper de la comunicació en les grans companyies, l'evolució de la publicitat i la importància del content i les experiències.</p>
      `,
      en: `
      <p>Oscar Ordóñez currently heads the MK&Experience Department at Caixabank. Under this unit he manages and leads the bank's commercial communication and advertising actions in large media, as well as the entire Branded content strategy on networks and platforms and the entertainment part with many strategic agreements that offer physical experiences and digital content. exclusive to the entity's clients. He leads the CaixabankXperience program that works in emotional territories such as music, culture, sports and gastronomy.</p>
      <p>Oscar began his professional career in advertising agencies, first in High Definition and then in Barcelona Virtual, the pioneering Internet advertising agency in Spain. After four years working for top-level brands, in 2000 he joined e-laCaixa as head of MK Digital, a subsidiary of the bank that was created to specialize in the management of digital projects. For 7 years he has led teams in the creation of portals, digital campaigns and content for ATMs and mobile phones. In 2007 he joined the Caixabank MK team, managing the segment MK management in which he developed the entity's loyalty programs (Children, LKXA - young people, multiEstrella - families - Club Ahora - seniors) and later took over the management of Individuals, grouping for several years the responsibility of loyalty and acquisition in a single marketing unit./p>
      <p>Under his direction, he actively collaborated, among other projects, in the creation and launch of Imaginbank and in the creation of such disruptive projects as ImaginCafe, Store offices and payment hubs.</p>
      <p>At Formentera20 Oscar will share his vision on the role of communication in large companies, the evolution of advertising and the importance of content and experiences.</p>
      `,
    },
    role: {
      es: "Director MK&Experience Caixabank ",
      ca: "Director MK&Experience Caixabank ",
      en: "MK&Experience Caixabank Director",
    },
  },
  {
    name: "Mª Jesús Espinosa de los Monteros",
    slug: "ma-jesus-espinosa-de-los-monteros",
    position: 3,
    company: "Prisa",
    url: "https://www.prisa.com/",
    image: "/speakers/2024/maria-jesus-espinosa.png",
    text: {
      es: `
        <p>Directora general de PRISA Audio, la plataforma que agrupa los contenidos de audio no lineal de todas las marcas de PRISA Media, tanto de radio como de prensa en España, Latinoamérica y Estados Unidos. Ganadora de 5 Premios Ondas (2013, 2016, 2020, 2021 y 2023) e impulsora de la Edición de los Premios Ondas Globales del Podcast. En 2019 fue elegida por la revista Forbes como una de las mentes más creativas en los negocios. Desde junio de 2022 es miembro del Consejo Rector de la Fundación de Periodismo Gabriel García Márquez. Es codirectora del primer Máster en Podcast y Audio Digital de la Escuela Barreira en Valencia (España). El pasado mes de noviembre recibió el Premio a Periodista del Año en los Premios Periodísticos Comunidad Valenciana.<p>
        <p>ERA DE LA AUDIFICACIÓN: PRESENTE Y FUTURO DE LOS CONTENIDOS SONOROS</p>
        <p>El mercado de la atención vira hacia lo sonoro con la convergencia de varias tendencias emergentes: asistentes personales, búsquedas por voz, audiolibros, música en streaming, apps de audio social y el podcast es uno de los formatos más innovadores del momento.</p>
        <p>En esta charla podrás conocer el panorama y ecosistema del podcasting a nivel mundial: productoras, plataformas y medios.</p>
        <p>Analizaremos también las claves para escribir pensando en sonido.</p>
        <p>Explicaremos, finalmente, por qué el audio es un formato de transformación digital para los medios y en qué consiste la llamada "Era de la Audificación"</p>
        `,
      ca: `
      <p>Directora general de PRISA Àudio, la plataforma que agrupa els continguts d'àudio no lineal de totes les marques de PRISA, tant de radio com de premsa a Espanya, Llatinoamèrica i els Estats Units. Guanyadora de 5 Premis Ondas (2013, 2016, 2020, 2021 i 2023) i impulsora de l'Edició dels Premis Ondas Globals del Podcast. En 2019 va ser triada per la revista Forbes com una de les ments més creatives en els negocis. Des de juny de 2022 és membre del Consell Rector de la Fundació de Periodisme Gabriel García Márquez. És codirectora del primer Màster en Podcast i Àudio Digital de l'Escola Barreira a València (Espanya). El mes de novembre passat va rebre el Premi a Periodista de l'Any en els Premis Periodístics Comunitat Valenciana.<p>
      <p>ERA DE LA AUDIFICACIÓ: PRESENT I FUTUR DELS CONTINGUTS SONORS</p>
      <p>El mercat de l'atenció vira cap al sonor amb la convergència de diverses tendències emergents: assistents personals, cerques per veu, audiollibres, música en *streaming, apps d'àudio social i el podcast és un dels formats més innovadors del moment.</p>
      <p>En aquesta xerrada podràs conèixer el panorama i ecosistema del podcasting a nivell mundial: productores, plataformes i mitjans.</p>
      <p>Analitzarem també les claus per a escriure pensant en so.</p>
      <p>Explicarem, finalment, per què l'àudio és un format de transformació digital per als mitjans i en què consisteix l'anomenada ‘Era de la Audificació’.</p>
        `,
      en: `
      <p>General Director of PRISA Audio, the platform that brings together non-linear audio content from all PRISA Media brands, both radio and press in Spain, Latin America and the United States. Winner of 5 Ondas Awards (2013, 2016, 2020, 2021 and 2023) and promoter of the Ondas Global Podcast Awards Edition. In 2019 she was chosen by Forbes magazine as one of the most creative minds in business. Since June 2022, he has been a member of the Governing Council of the Gabriel García Márquez Journalism Foundation. She is co-director of the first Master's Degree in Podcast and Digital Audio at the Barreira School in Valencia (Spain). Last November he received the Journalist of the Year Award at the Valencian Community Journalism Awards<p>
      <p>ERA OF AUDIFICATION: PRESENT AND FUTURE OF SOUND CONTENT</p>
      <p>The attention market is turning towards sound with the convergence of several emerging trends: personal assistants, voice searches, audiobooks, streaming music, social audio apps and the podcast is one of the most innovative formats of the moment.</p>
      <p>In this talk you will be able to learn about the panorama and ecosystem of podcasting worldwide: production companies, platforms and media.We will also analyze the keys to writing thinking about sound.</p>
      <p>Finally, we will explain why audio is a digital transformation format for the media and what the so-called 'Audiification Era' consists of.</p>
      `,
    },
    role: {
      es: `Directora General de PRISA Audio`,
      ca: `Directora General de PRISA Audio`,
      en: `General Director of PRISA Audio`,
    },
  },
  {
    name: "Sebastián Sánchez",
    slug: "sebastian-sanchez",
    position: 4,
    company: "Manifiesto",
    url: "www.manifiesto.biz",
    image: "/speakers/2024/sebastian-sanchez.jpg",
    text: {
      es: `
      <p>Sebastián inicia su carrera en New York en The Vidal Partnership. En Colombia sus primeros trabajos los realiza en DDB como copywriter. Luego ingresó a Y&R donde dirigió el grupo más grande de la agencia. Tras 6 años en Y&R asume un nuevo reto en Findasense, empresa española, como Director Creativo Regional para The Coca-Cola Company. Hace 6 años llega a Europa ocupando el cargo de Director Creativo en VMLY&R Madrid. Después de una pausa en la que decide emprender en el mundo de la gastronomía con su restaurante Fantastic V, vuelve a la industria asumiendo el cargo de Director Creativo de Proyectos Especiales en Manifiesto liderando proyectos de innovación, social media, New Business para las oficinas de Madrid y Barcelona.</p>
      <p>Hoy un año después de su incorporación asume el cargo de Chief Creative Officer de la agencia. Ha trabajado para The Coca-Cola Company, Clorox, Unilever, SABMiller, TOYOTA, Porsche, Land Rover, Fiat, Alfa Romeo, Suzuki, Hyundai, Adidas, Energizer, Honda, Fuerzas Militares de Colombia, Goodyear, Telefónica, Movistar, Danone, Sprint, Krispy Kreme, Diageo, Texaco, Opel, Directv, Warner Media, PlayStation, Latam, X-Box, entre otras marcas.</p>
      <p>Palmarés: Cannes Lions, Clio, LIA, D&AD, New York Festival, Webby Awards, One Show, Fiap, Ojo de Iberoamérica o el Sol, entre otros festivales locales.</p>
      `,
      ca: `
      <p>Sebastián inicia la seva carrera a Nova York a The Vidal Partnership. A Colòmbia els seus primers treballs els realitza a DDB com a copywriter. Després va ingressar a I&R on va dirigir el grup més gran de l'agència. Després de 6 anys a I&R assumeix un nou repte a Findasense, empresa espanyola, com a Director Creatiu Regional per a The Coca-Cola Company. Fa 6 anys arriba a Europa ocupant el càrrec de Director Creatiu a VMLY&R Madrid. Després d'una pausa en la qual decideix emprendre en el món de la gastronomia amb el seu restaurant Fantastic V, torna a la indústria assumint el càrrec de Director Creatiu de Projectes Especials a Manifiesto liderant projectes d'innovació, social media, New Business per a les oficines de Madrid i Barcelona. </p>
      <p>Un any després de la seva incorporació assumeix el càrrec de Chief Creative Officer de l'agència. Ha treballat per a The Coca-Cola Company, Clorox, Unilever, SABMiller, TOYOTA, Porsche, Land Rover, Fiat, Alfa Romeo, Suzuki, Hyundai, Adidas, Energizer, Honda, Forces Militars de Colòmbia, Goodyear, Movistar, Danone, Sprint, Krispy Kreme, Diageo, Texaco, Opel, Directv, Warner Media, Latam, X-Box, PlayStation, entre altres marques.</p>
      <p>Palmarès: Cannes Lions, Clio, LIA, DAD, Nova York Festival, Webby Awards, The One Show NY, Fiap, Ojo de Iberoamèrica o el Sol, entre altres festivals locals.</p>
      `,
      en: `
      <p>Sebastián began his career in New York at The Vidal Partnership. In Colombia his first jobs were at DDB as a copywriter. He then joined Y&R where he managed the largest group of the agency. After 6 years at Y&R he took on a new challenge at Findasense, a Spanish company, as Regional Creative Director for The Coca-Cola Company. Six years ago he arrived in Europe as Creative Director at VMLY&R Madrid. After a pause in which he decided to venture into the world of gastronomy with his restaurant Fantastic V, he returned to the industry as Creative Director of Special Projects at Manifiesto, leading projects of innovation, social media, New Business for the offices in Madrid and Barcelona.  </p>
      <p>A year after his incorporation, he assumes the position of Chief Creative Officer of the agency. He has worked for The Coca-Cola Company, Clorox, Unilever, SABMiller, TOYOTA, Porsche, Land Rover, Fiat, Alfa Romeo, Suzuki, Hyundai, Adidas, Energizer, Honda, Fuerzas Militares de Colombia, Goodyear, Telefónica, Movistar, Danone, Sprint, Krispy Kreme, Diageo, Texaco, Coca-Cola, Opel, Directv, Warner Media, Latam, X-Box, PlayStation, among other brands.</p>
      <p>Awards: Cannes Lions, Clio, LIA, D&AD, New York Festival, Webby Awards, One Show, Fiap, Ojo de Iberoamérica or El Sol, among other local festivals.</p>
      `,
    },
    role: {
      es: "Sebastián Sanchez, Chief Creative Officer.",
      ca: "Sebastián Sanchez, Chief Creative Officer.",
      en: "Sebastián Sanchez, Chief Creative Officer.",
    },
  },
  {
    name: "Rosario Sierra",
    slug: "rosario-sierra",
    position: 5,
    company: "Linkedin",
    url: "www.linkedin.com",
    image: "/speakers/2024/rosario-sierra.png",
    text: {
      es: `
      <p>Más de 20 años de experiencia en desarrollo comercial, gestión de ventas y gestión de equipos, ambos en empresas multinacionales de rápido crecimiento en las industrias de Internet y tecnología. Acostumbrada a trabajar en entornos dinámicos y cambiantes. LinkedIn - 11 años.</p>
      <p>Rosario Sierra ocupa actualmente el puesto de Directora de Negocio Corporativo de LinkedIn España y Portugal. Se incorporó a LinkedIn en el año 2012, donde ha desarrollado su carrera en el desarrollo de negocio y la gestión de ventas y equipos y fue la primera mujer que contrató LinkedIn en España. Además, Rosario dispone de una amplia formación en las áreas de Psicología de la Organizaciones y Marketing Digital, que le ha llevado a impulsar su carrera en dos grandes empresas tecnológicas multinacionales como Google y LinkedIn. Anteriormente desempeño su carrera en puestos de desarrollo de negocio en empresas como Manpower y en su etapa internacional desarrollada en Londres como directora regional de ventas y marketing en Optima y Pinpoint Consulting. En 2021 fue elegida como una de las TOP 100 mujeres líderes de España y actualmente se encuentra de consejera de Competencias Digitales para el Ministerio de Economía y participando en fundaciones y publicando numerosos artículos, además de dar clases en escuelas de negocio como ESADE, The Valley e Instituto de Empresa y Mentora y Coach en diferentes organismos europeos.</p>
      `,
      ca: `
      <p>Més de 20 anys d'experiència en desenvolupament comercial, gestió de vendes i gestió d'equips, tots dos en empreses multinacionals de ràpid creixement en les indústries d'Internet i tecnologia. Acostumada a treballar en entorns dinàmics i canviants. Linkedin - 11 anys.</p>
      <p>Rosario Sierra ocupa actualment el lloc de Directora de Negoci Corporatiu de Linkedin Espanya i Portugal. Es va incorporar a Linkedin l'any 2012, on ha desenvolupat la seva carrera en el desenvolupament de negoci i la gestió de vendes i equips i va ser la primera dona que va contractar Linkedin a Espanya. A més, Rosario disposa d'una àmplia formació en les àrees de Psicologia de l'Organitzacions i Màrqueting Digital, que li ha portat a impulsar la seva carrera en dues grans empreses tecnològiques multinacionals com Google i Linkedin. Anteriorment exerceixo la seva carrera en llocs de desenvolupament de negoci en empreses com Manpower i en la seva etapa internacional desenvolupada a Londres com a directora regional de vendes i màrqueting en *Optima i Pinpoint Consulting. En 2021 va ser triada com una de les TOP 100 dones líders d'Espanya i actualment es troba de consellera de Competències Digitals per al Ministeri d'Economia i participant en fundacions i publicant nombrosos articles, a més de fer classes en escoles de negoci com ESADE, The Valley i Institut d'Empresa i Mentora i Coach en diferents organismes europeus.</p>
      `,
      en: `
      <p>More than 20 years of experience in business development, sales management and team management, both in fast-growing multinational companies in the Internet and technology industries. Accustomed to working in dynamic and changing environments. LinkedIn - 11 years.</p>
      <p>Rosario Sierra currently holds the position of Corporate Business Director of LinkedIn Spain and Portugal. She joined LinkedIn in 2012, where she has developed her career in business development and sales and team management and was the first woman hired by LinkedIn in Spain. In addition, Rosario has extensive training in the areas of Organizational Psychology and Digital Marketing, which has led him to promote his career in two large multinational technology companies such as Google and LinkedIn. Previously, she spent her career in business development positions in companies such as Manpower and in her international stage in London as regional sales and marketing director at Optima and Pinpoint Consulting. In 2021 she was chosen as one of the TOP 100 leading women in Spain and is currently advisor of Digital Skills for the Ministry of Economy and participating in foundations and publishing numerous articles, in addition to teaching at business schools such as ESADE, The Valley and Business Institute and Mentor and Coach in different European organizations.</p>
      `,
    },
    role: {
      es:
        "Directora de Desarrollo de Negocio Corporativo España y Portugal, Linkedin.",
      ca:
        "Directora de Desenvolupament de Negoci Corporatiu Espanya i Portugal, Linkedin.",
      en:
        "Director of Corporate Business Development Spain and Portugal, Linkedin.",
    },
  },

  {
    name: "Sergio Méndez",
    slug: "sergio-mendez",
    position: 7,
    company: "Warner Music",
    url: "www.warnermusic.es",
    image: "/speakers/2024/sergio-mendez.png",
    text: {
      es: `
        <p>Sergio Méndez actualmente ocupa el cargo de Artist Services & Institutional Relations Director en
        Warner Music Spain, compañía a la que se unió en 2019 y donde ha desempeñado diversas funciones que le han permitido liderar equipos creativos y de contenido de marca.
        </p>
        <p>Dirigió el proyecto de transformación de la compañía, culminando con la inauguración en 2022 de "The Music Station", el primer HUB artístico y creativo en España abierto las 24 horas, los 7 días de la semana para artistas, autores y creadores de contenido. Este proyecto implicó la recuperación de un edificio histórico y protegido, la Estación del Norte de Príncipe Pío en Madrid.
        </p>
        <p>A lo largo de la última década, ha trabajado para empresas como 2btube INC., una startup líder en contenido digital con audiencia en España y LATAM, con 1.800 millones de visualizaciones y 650 creadores de contenido, desempeñando el cargo de Director de Desarrollo de Negocios. Previamente, trabajó como Director de Estrategia y Desarrollo en la consultora de comunicación Interprofit y como Director de Cuentas y Estrategia en la Agencia Creativa Tangoº.
        </p>
        <p>Su trayectoria profesional le ha permitido trabajar y liderar equipos desde la perspectiva de agencia creativa/digital y desde el punto de vista de marca/anunciante. </p>
        `,
      ca: `
      <p>Sergio Méndez és actualment Artist Services & Institutional Relations Director en
      Sergio Méndez és actualment Artist Services & Institutional Relations Director en
      Warner Music Spain, companyia a la qual és va unir en 2019 i en la qual ha ocupat
      diferents posicions que li han permès liderar equips creatius, de brandend
      content.
      </p>
      <p>Va liderar el projecte de transformació de la companyia culminat amb la inauguració en 2022 de “The Music Station” el primer HUB artístic i creatiu a Espanya obert 24/7
      per a artistes, autors, i creadors de contingut, recuperant un edifici històric i
      protegit com és l'Estació del Nord de Príncep Pio a Madrid.</p>
      <p>Durant l'última dècada ha treballat per a companyies com 2btube INC., startup de
      contingut digital líder en audiència a Espanya i LATAM amb 1.800M de views i 650
      creadors de contingut com Head of Business Development, anteriorment com a
      Director d'Estratègia i Desenvolupament per a la consultora de comunicació Interprofit i com a director de Comptes i Estratègia en l'Agència Creativa Tangoº</p>
      <p>La seva trajectòria professional li ha facilitat treballar i liderar equips des del punt de vista d'agència creativa/digital i des del punt de vista de marca/anunciant.</p>
      `,
      en: `
      <p>Sergio Méndez is currently Artist Services & Institutional Relations Director at
      Warner Music Spain, a company that he is going to join in 2019 and in which he has occupied different positions that have allowed him to lead teams creatius, from brandend
      content and partnerships.</p>
      <p>He will lead the company's transformation project culminating in the inauguration in 2022 of “The Music Station”, the first artistic and creative HUB in Spain open 24/7
      For artists, authors, and creators of contingut, recover a historic building and
      protect with the Estació del Nord de Príncep Pio in Madrid.</p>
      <p>Over the last decade he has worked for a company with 2btube INC., a startup
      digital contingut leader in audience in Spain and LATAM with 1,800M views and 650
      creators of contingut com Head of Business Development, formerly com a
      Director of Strategy and Development for the communication consultancy Interprofit icom to Director of Comptes and Strategy at the Creative Agency Tangoº</p>
      <p>His professional background has made it easier to work and lead teams from the creative/digital agency point of view and from the brand/advertising point of view.</p>
      `,
    },
    role: {
      es:
        "Artist Services & Institutional Relations Director en Warner Music Spain.",
      ca:
        "Artist Services & Institutional Relations Director en Warner Music Spain.",
      en:
        "Artist Services & Institutional Relations Director en Warner Music Spain.",
    },
  },
  {
    name: "Gonzalo Figari",
    slug: "gonzalo-figari",
    position: 6,
    company: "Club de Creativos",
    url: "www.clubdecreativos.com",
    image: "/speakers/2024/gonzalo-figari.png",
    text: {
      es: `
        <p>Gonzalo Figari Trabajó en Y&R Perú y otras agencias locales. Luego Gonzalo trabajó en Chile en Y&R, MC CANN, OGILVY y finalmente fue el director creativo regional para LATAM en WUNDERMAN. De allí se fue a trabajar a Argentina como una exportación no tradicional y luego fue contratado en GRUPO CONTRAPUNTO BBDO como director creativo para CANAL+ desde CPCOMUNICACIÓN.  A los 6 meses montó su propia agencia. REMOD6 con oficinas en Madrid, Valencia y Lima. Tras 17 años fue fichado por DENTSU como Head of Disruption en España. Ahora Gonzalo tiene su proyecto más personal THE CANDY STORE y hacen campañas y contenidos para diversos mercados. No vamos a hablar ni cuantos premios tiene, ni en qué festivales ha dado conferencias, ni mucho menos cuántas veces ha sido jurado o de presidente de jurado. Pero si vale la pena comentar que hace 5 meses fue nombrado presidente del Club de Creativos de España. Lo más curioso de este CV es que ha sido generado por inteligencia artificial. 
        ¡Mentira! jajaja. ¿O sí? Who Knows…</p>
        `,
      ca: `
      <p>Gonzalo Figari va treballar en I&R Perú i altres agències locals. Després Gonzalo va treballar a Xile en I&R, MC CANN, OGILVY i finalment va ser el director creatiu regional per a *LATAM en *WUNDERMAN. D'allí es va anar a treballar a l'Argentina com una exportació no tradicional i després  contractat en GRUP CONTRAPUNT BBDO com a director creatiu per a CANAL+ des de CPCOMUNICACIÓN. Als 6 mesos va muntar la seva pròpia agència. REMOD6 amb oficines a Madrid, València i Lima. Després de 17 anys va ser fitxat per *DENTSU com *Head *of *Disruption a Espanya. Ara Gonzalo té el seu projecte més personal *THE CANDY *STORE i fan campanyes i continguts per a diversos mercats. No parlarem ni quants premis té, ni en quins festivals ha donat conferències, ni molt menys quantes vegades ha estat jurat o de president de jurat. Però si val la pena comentar que fa 5 mesos va ser nomenat president del Club de Creatius d'Espanya. El més curiós d'aquest CV és que ha estat generat per intel·ligència artificial. 
      Mentida!  O sí?</p>
      `,
      en: `
      <p>Gonzalo Figari Worked at Y&R Peru and other local agencies. Gonzalo then worked in Chile at Y&R, MC CANN, OGILVY and finally was the regional creative director for LATAM at WUNDERMAN. From there he went to work in Argentina as a non-traditional export and was then hired at GRUPO CONTRAPUNTO BBDO as creative director for CANAL + from CPCOMUNICACIÓN. After 6 months he set up his own agency. REMOD6 with offices in Madrid, Valencia and Lima. After 17 years he was hired by DENTSU as Head of Disruption in Spain. Now Gonzalo has his most personal project THE CANDY STORE and they make campaigns and content for various markets. We are not going to talk about how many awards he has, or at which festivals he has given lectures, much less how many times he has been a jury member or jury president. But it is worth commenting that 5 months ago he was named president of the Creative Club of Spain. The most curious thing about this CV is that it has been generated by artificial intelligence.
      Who Knows…;)</p>
        `,
    },
    role: {
      es: "Gonzalo Figari. Presidente del Club de Creativos.",
      ca: "Gonzalo Figari, President del Club de Creativos.",
      en: "Gonzalo Figari. President of the Club de Creativos ",
    },
  },
];

module.exports = data;
